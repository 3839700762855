import { Dispatch, MutableRefObject, ReactNode, SetStateAction } from 'react'
import DropinElement from '@adyen/adyen-web/dist/types/components/Dropin/Dropin'
import RedirectElement from '@adyen/adyen-web/dist/types/components/Redirect/Redirect'
import Checkout from '@adyen/adyen-web/dist/types/core'
import { Session } from 'next-auth'
import {
  CheckoutDetails,
  CheckoutPaymentRequest,
  CustomerAddress,
  DeliveryType,
  OrderSummary,
  PaymentMethod,
  ShippingMethod,
} from 'shared-types'

export type CheckoutInstanceProps = Checkout

export interface CheckoutPaymentSessionProps {
  id: string
  session: string
  clientId: string
  environment: string
}

export enum CheckoutSteps {
  Contact = 1,
  Delivery = 2,
  Payment = 3,
  Order = 4,
}

export enum CustomerDetailState {
  Default = 'Default',
  NewAddress = 'NewAddress',
  SavedAddress = 'SavedAddress',
  EditAddress = 'EditAddress',
}

export interface GiftCardInfo {
  cardNumber: string
  cardType: 'legacy' | 'vii' | null
  pinCode?: string
  isValid?: boolean
}

export interface CheckoutPaymentMethodProps extends PaymentMethod {
  type?: string
  autoSubmission?: boolean
  componentId?: string
  value?: string
  iconName?: string
  icon?: ReactNode
}

export interface ShiipDeliveryInfo {
  message?: string
  deliveryOptions: ShippingMethod[]
}

export interface CheckoutProviderProps {
  checkoutStep: CheckoutSteps
  isAddedDiscountCode: boolean
  paymentInstance: CheckoutInstanceProps
  loading: boolean
  selectedSavedAddress?: CustomerAddress
  error?: string
  addDiscountCode?: (code: string, session: Session) => Promise<void>
  adyenError?: string
  placeOrder?: () => Promise<void>
  continueToDelivery?: (formdata: Record<string, unknown>) => Promise<boolean>
  continueToPayment?: (payload: CheckoutPaymentRequest) => Promise<void>
  setCheckoutStep?: (checkoutStep: number) => void
  getPaymentSession?: (payload: CheckoutPaymentRequest) => void
  checkoutDetails: CheckoutDetails
  orderDetails: OrderSummary
  updateDeliveryType?: (deliveryType: DeliveryType) => void
  customerDetailState?: CustomerDetailState
  setCustomerDetailState?: (state: CustomerDetailState) => void
  handleCartChangeQuantity?: (
    id: string,
    sku: string,
    quantity: number
  ) => Promise<void>
  addInsurance?: () => Promise<void>
  removeInsurance?: () => Promise<void>
  payformInstance?: RedirectElement
  setPayformInstance?: (instance: RedirectElement) => void
  setSelectedSavedAddress?: (address: CustomerAddress | null) => void
  setLoading?: (loading: boolean) => void
  removeDiscountCode?: (session: Session) => Promise<void>
  clearError?: () => void
  paymentType?: string
  updateShippingMethod?: (deliveryCode: string) => void
  applyCredit?: () => Promise<boolean>
  setBillingAddress?: (formdata: Record<string, unknown>) => Promise<boolean>
  getLatitudeSession?: () => Promise<string>
  isCardValid: boolean
  setCardFormInstance?: (instance: DropinElement) => void
  cardFormInstance?: DropinElement
  giftCardInfo?: GiftCardInfo
  setGiftCardInfo?: (state?: GiftCardInfo) => void
  redeemGiftCard?: () => Promise<boolean>
  setIsCardValid?: (state: boolean) => void
  selectedPayment?: CheckoutPaymentMethodProps
  setSelectedPayment?: (state: CheckoutPaymentMethodProps) => void
  placeFound?: boolean
  setPlaceFound?: (state: boolean) => void
  showPlaceOrderButton?: boolean
  setShowPlaceOrderButton?: (state: boolean) => void
  redirectLoading?: boolean
  setRedirectLoading?: (state: boolean) => void
  setError?: (state: string) => void
  addCoupon?: (count: number) => void
  removeCoupon?: () => void
  isTransactionDeclined?: boolean
  checkoutPaymentStepId?: number
  setCheckoutPaymentStepId?: Dispatch<SetStateAction<number>>
  hasCardsDropInMounted?: MutableRefObject<boolean>
  setShowMethods?: Dispatch<SetStateAction<boolean>>
  isNewCardSaved?: MutableRefObject<boolean>
  storesPurchaseOrderNumber?: string
  setStoresPurchaseOrderNumber?: (state: string) => void
  lineItemsSkuList?: string
  shiipDeliveryInfo?: ShiipDeliveryInfo
  fetchShiipDeliveryInfo?: () => Promise<void>
}
